html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background: #eaeef3;
  color: #333;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 24px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.nb-theme-default a,
.nb-theme-corporate a {
  color: #222b45 !important;
  font-weight: 600;
  text-decoration: none;
}

.nb-theme-dark a,
.nb-theme-cosmic a {
  color: #fff !important;
  font-weight: 600;
  text-decoration: none;
}

.nb-theme-dark .ng2-smart-title,
.nb-theme-corporate .ng2-smart-title {
  font-weight: 600;
}

.forgot-pwd a {
  font-weight: 400;
}

.form-element {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 15px 0;
}

.form-element select:focus {
  border: 0;
  border-bottom: solid 1px #282829;
  outline: none;
}

.form-element input:focus {
  border-bottom: solid 1px #282829;
  outline: none;
}

.form-element textarea:focus {
  border-bottom: solid 1px #000;
}

.form-element label {
  color: #959595;
  font-weight: 400;
  transition: all 200ms ease 0s;
  white-space: nowrap;
}

.form-element label.checkbox {
  cursor: pointer;
}

input:autofill {
  background-color: #fff !important;
}

input:focus {
  color: red;
  outline: 2px solid orange;
}

/**  check box  **/

.ng2-smart-title {
  font-weight: 600;
}

ng2-smart-table table tr.ng2-smart-titles th {
  padding: 1rem;
  padding-right: 1rem !important;
}

ng2-smart-table nav.ng2-smart-pagination-nav {
  margin: 1.25em auto 0;
}

ng2-smart-table nav.ng2-smart-pagination-nav .ng2-smart-pagination {
  margin: 0;
}

.checkbox,
.radio {
  position: relative;
  display: inline-block;
  height: 20px;
  padding: 0 0 0 25px;
  float: left;
  vertical-align: middle;
}

.checkbox input,
.radio input {
  z-index: 1;
  top: 0;
  left: 0;
  margin: 0 !important;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-top: 4px \9;
  margin-left: -20px;
}

.checkbox .input-helper::before,
.radio .input-helper::before,
.checkbox .input-helper::after,
.radio .input-helper::after {
  position: absolute;
  backface-visibility: hidden;
  content: "";
  transition: all;
  transition-duration: 250ms;
}

.checkbox .input-helper::before,
.radio .input-helper::before {
  left: 0;
  border: 1px solid #ccc;
}

.nb-theme-dark .radio .input-helper::before {
  left: 0;
  border: 1px solid #fff;
}

.checkbox.disabled,
.radio.disabled {
  filter: alpha(opacity=60);
  opacity: 0.6;
}

.checkbox input {
  width: 17px;
  height: 17px;
}

.checkbox input:checked + .input-helper::before {
  transform: scale(0);
}

.checkbox input:checked + .input-helper::after {
  filter: alpha(opacity=100);
  opacity: 1;
  transform: scale(1) rotate(-50deg);
}

.checkbox .input-helper::before {
  top: 3px;
  width: 17px;
  height: 17px;
}

.checkbox .input-helper::after {
  top: 1px;
  left: -1px;
  width: 22px;
  height: 9px;
  border-bottom: 2px solid #282829;
  border-left: 2px solid #282829;
  border-bottom-left-radius: 2px;
  filter: alpha(opacity=0);
  opacity: 0;
  transform: scale(0) rotate(80deg);
}

/**  form  **/

.form-element input[type="text"],
.form-element input[type="password"],
.form-element textarea,
.form-element input[type="email"],
.form-element input[type="search"],
.form-element select {
  display: block;
  width: 100%;
  height: 35px;
  padding: 0;
  border: none;
  border-bottom: 1px solid #e0e0e0;
  appearance: none;
  background-color: #fff !important;
  border-radius: 0;
  color: #666;
  font-size: 14px;
  line-height: 1.42857143;
}

.nb-theme-dark .inner-page .form-element input[type="text"],
.nb-theme-dark .inner-page .form-element input[type="password"],
.nb-theme-dark .inner-page .form-element input[type="email"],
.nb-theme-dark .inner-page .form-element input[type="search"] {
  height: 42px;
  border-bottom: 0.0625rem solid #101426;
  background-color: #192038 !important;
  color: #fff;
}

.form-element input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.form-element textarea {
  height: 80px !important;
}

.form-element textarea:focus,
.form-element textarea:focus-visible {
  outline: none !important;
}

.loginType_3 .form-element {
  padding: 5px 10px;
  border: 1px solid rgb(0 0 0 / 7%);
  margin: 7px 0;
}

.loginType_3 .form-element input[type="text"],
.loginType_3 .form-element input[type="password"],
.loginType_3 .form-element input[type="email"],
.loginType_3 .form-element input[type="search"],
.loginType_3 .form-element select {
  border-bottom: 0 solid #e0e0e0;
  margin-top: -8px;
}

.loginType_3 .form-element textarea {
  border-bottom: 0 solid #e0e0e0;
}

.loginType_3 .form-element.forgot-pwd,
.loginType_3 .form-element.buttons {
  padding: 0;
  border: 0;
}

.loginType_3 .button {
  padding: 15px 24px;
}

.loginType_4 .form-element input[type="text"],
.loginType_4 .form-element input[type="password"],
.loginType_4 .form-element input[type="email"],
.loginType_4 .form-element input[type="search"],
.loginType_4 .form-element textarea {
  height: 48px;
  padding: 0 10px;
  border: 1px solid rgb(0 0 0 / 20%);
  border-radius: 2px;
}

.loginType_4 .button {
  padding: 15px 24px;
}

.loginType_4.inner-page .col-set-6 .form-element .upload-img {
  border: 1px solid rgb(0 0 0 / 20%);
}

.closeFilter {
  position: absolute;
  z-index: 1;
  top: 31px;
  left: -20px;
  cursor: pointer;
}

/* button */

.button {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 10px 24px;
  border: 0 none;
  border: none;
  background: #282828;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  vertical-align: middle;
}

.button.unfill {
  background: #ff5c5c;
  border-radius: 1px;
  color: #fff;
}

.button::before {
  position: absolute;
  width: 0;
  height: 1px;
  background: #fff;
  content: "";
  transition: all 0.2s linear;
  top: 3px;
  left: 50%;
  transition-duration: 0.4s;
}

.button::after {
  position: absolute;
  width: 0;
  height: 1px;
  background: #fff;
  content: "";
  transition: all 0.2s linear;
  bottom: 3px;
  left: 50%;
  transition-duration: 0.4s;
}

.button:hover {
  background: #333;
  color: #fff;
}

.button span::before,
.button span::after {
  position: absolute;
  width: 1px;
  height: 0;
  background: #fff;
  content: "";
  transition: all 0.2s linear;
}

.button:hover::before,
.button:hover::after {
  width: 100%;
}

.button:hover span::before,
.button:hover span::after {
  left: 3px;
  width: calc(100% - 6px);
  top: 3px;
  height: calc(100% - 6px);
}

.button span::before {
  top: 50%;
  left: 3px;
  transition-duration: 0.4s;
}

.button span::after {
  top: 50%;
  right: 3px;
  transition-duration: 0.4s;
}

/**  table  **/

ng2-st-tbody-custom {
  display: flex;
  height: 100%;
}

ng2-st-tbody-edit-delete {
  display: none !important;
}

.nb-theme-default ng2-smart-table thead tr.ng2-smart-filters th {
  padding: 0;
}

/**  profile page  **/

.inner-page .form-element.upload {
  padding: 0;
  border: 0 !important;
}

.inner-page .form-bx {
  padding: 1rem 0;
}

.inner-page .form-element label {
  font-size: 13px;
}

.inner-page .col-set-6 {
  border: 0.0625rem solid #e4e9f2;
  margin-bottom: 1.875rem;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: none;
  color: #222b45;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.inner-page .col-set-6 .tab.ng-star-inserted {
  border-bottom: 1px solid #edf1f7;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #222b45;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.inner-page .col-set-6 .upload-img {
  position: relative;
  z-index: 1;
  width: 100px;
  height: 100px;
  padding: 0;
  border: 1px solid rgb(0 0 0 / 7%);
  margin-top: 10px;
  margin-right: 15px;
  border-radius: 1px;
  line-height: 100px;
  text-align: center;
}

.inner-page .col-set-6 .upload-img .img-border {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-top: 0;
  margin-right: 10px;
  font-size: 1.1em;
  font-weight: 500;
  opacity: 0;
  text-align: center;
  vertical-align: middle;
}

.inner-page .col-set-6 .upload-img .upload-photo {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  outline: none;
  text-align: center;
}

.inner-page .col-set-6 nb-tabset .tab.active .tab-link {
  color: #222b45;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1rem;
  text-transform: capitalize;
}

.inner-page .col-set-6 nb-tabset .tabset .tab a::before {
  bottom: -10px;
  border-radius: 1px;
}

.inner-page .col-set-6 nb-tabset .tab-link::before {
  height: 0.15rem;
}

.inner-page .col-set-6 nb-tabset .tab.active .tab-link::before {
  background-color: #222b45;
}

.nb-theme-default nb-tabset .tab-link {
  color: #959595;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1rem;
  text-transform: capitalize;
}

/**  dark theme  **/

.nb-theme-dark .inner-page .col-set-6 {
  border: 0.0625rem solid #101426;
  background-color: #222b45;
  color: #fff;
}

.nb-theme-dark .inner-page .form-element label {
  color: #8f9bb3;
}

.nb-theme-dark .loginType_4.inner-page .form-element input[type="text"],
.nb-theme-dark .loginType_4.inner-page .form-element input[type="password"],
.nb-theme-dark .loginType_4.inner-page .form-element input[type="email"],
.nb-theme-dark .loginType_4.inner-page .form-element input,
.nb-theme-dark .loginType_4.inner-page .form-element select {
  border: 0.0625rem solid #101426;
  background-color: #192038 !important;
}

.nb-theme-dark .loginType_4.inner-page .col-set-6 .form-element .upload-img,
.nb-theme-dark .loginType_3.inner-page .col-set-6 .form-element .upload-img,
.nb-theme-dark .loginType_2.inner-page .col-set-6 .form-element .upload-img,
.nb-theme-dark .loginType_1.inner-page .col-set-6 .form-element .upload-img {
  border: 0.0625rem solid #101426;
}

.nb-theme-dark .inner-page .button {
  background: #101426;
}

.nb-theme-dark .inner-page .col-set-6 nb-tabset .tab.active .tab-link {
  color: #36f;
  font-weight: 700;
  letter-spacing: 1px;
}

.nb-theme-dark .inner-page .col-set-6 nb-tabset .tab.active .tab-link::before {
  bottom: -1px !important;
  background-color: #36f;
}

.nb-theme-dark .inner-page .col-set-6 nb-tabset .tab-link {
  letter-spacing: 1px;
  text-transform: capitalize;
}

.nb-theme-dark .inner-page.loginType_3 .form-element {
  border: 0.0625rem solid #101426;
  background: #192038 !important;
}

.nb-theme-dark .inner-page.loginType_3 .form-element.forgot-pwd {
  background: none !important;
}

.nb-theme-dark .inner-page.loginType_3 .form-element.upload {
  background: none !important;
}

.nb-theme-dark .loginType_3.inner-page .col-set-6 .form-element .upload-img {
  background: #192038 !important;
}

.inner-page.commonSpacing .col-set-6 h2 {
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #edf1f7;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #222b45;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.5rem;
}

.inner-page.commonSpacing .col-set-6 .form-bx {
  padding: 1rem 1.5rem;
}

.inner-page.commonSpacing .col-set-6 .form-element ul {
  padding: 0;
  margin-bottom: 0;
}

.inner-page.commonSpacing .col-set-6 .form-element.access ul li {
  display: inline-block;
  width: 33.33%;
  list-style: none;
}

.nb-arrow-left {
  display: inline-block;
  margin-top: 4px;
  font-size: 17px;
  font-weight: 800;
  vertical-align: top;
}

.add-more-container {
  position: fixed;
  z-index: 9;
  right: 20px;
  bottom: 15px;
}

.add-more-container a {
  display: block;
  width: 60px;
  height: 60px;
  background: #333;
  border-radius: 50%;
  box-shadow: 0 0 20px 4px rgb(0 0 0 / 30%);
  color: #fff !important;
  cursor: pointer;
  font-size: 25px;
  font-size: 28px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  transition: all 0.2s ease-out;
}

.nb-theme-dark .loginType_3.inner-page .form-element input[type="text"],
.nb-theme-dark .loginType_3.inner-page .form-element input[type="password"],
.nb-theme-dark .loginType_3.inner-page .form-element input[type="email"],
.nb-theme-dark .loginType_3.inner-page .form-element input [type="search"],
.nb-theme-dark .loginType_3.inner-page .form-element select,
.nb-theme-dark .form-element textarea {
  border-bottom: 0;
  background-color: #192038 !important;
  color: #fff;
}

.nb-theme-dark .inner-page.commonSpacing .col-set-6 h2 {
  border-bottom: 1px solid #151a30;
  color: #fff;
}

.nb-theme-dark .inner-page.loginType_3 .form-element.buttons.add-achievement {
  border-color: transparent !important;
  background: transparent !important;
}

.nb-theme-dark .searchBox .form-control {
  border-color: #101426;
  background-color: #192038;
}

/**  filter  **/

nb-card-header {
  position: relative;
}

.filerList {
  position: absolute;
  top: 12px;
  right: 15px;
}

.filerList ul {
  padding: 0;
}

.filerList ul li {
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  list-style: none;
}

.filerList nb-icon {
  color: #8f9bb3;
}

.inner-page .col-set-6.filter {
  border: 0;
  margin-bottom: 0;
  background-color: transparent;
}

.form-element.forgot-pwd {
  background: none !important;
}

/* stats */

nb-card-body {
  min-width: 600px;
  padding: 1.5rem;
}

.total-stats {
  position: relative;
  padding-top: 15px;
  margin-top: 15px;
}

.total-stats::before {
  position: absolute;
  top: 0;
  right: -25px;
  left: -25px;
  border-top: 1px solid #edf1f7;
  content: "";
}

.total-stats .col {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: auto;
  box-sizing: border-box;
  border: 1px solid #edf1f7;
  margin: 5px;
  background: none;
  background: #fff;
  border-radius: 2px;
  line-height: 25px;
}

.total-stats .col a {
  display: block;
  box-sizing: border-box;
  padding: 8px 0 8px 8px;
  font-weight: 300;
}

.total-stats .col.full a {
  padding: 8px;
}

.total-stats .col span {
  padding: 0 5px 0 15px;
  padding: 0 15px;
  border-left: 1px solid #edf1f7;
  margin-left: 10px;
  font-size: 18px;
}

.total-stats::after {
  position: absolute;
  top: 0;
  right: -24px;
  bottom: -16px;
  left: -24px;
  background-color: #f7f9fc;
  content: "";
}

.nb-theme-dark .total-stats::after {
  background-color: #222b45;
}

.nb-theme-dark .total-stats::before {
  border-top: 1px solid #151a30;
}

.nb-theme-dark .total-stats .col {
  border: 1px solid #36f;
  background: #36f;
}

.nb-theme-cosmic .total-stats::after {
  background-color: #323259;
}

.nb-theme-cosmic .total-stats .col a,
.nb-theme-dark .total-stats .col a {
  color: #fff;
  text-decoration: none;
}

.nb-theme-cosmic .total-stats::before {
  border-top: 1px solid #151a30;
}

.nb-theme-cosmic .total-stats .col {
  border: 1px solid #a16eff;
  background: #a16eff;
}

ng2-smart-table thead tr.ng2-smart-filters th {
  display: none;
}

.nb-theme-dark .add-more-container a,
.nb-theme-dark .button {
  background: #36f;
}

.nb-theme-cosmic .add-more-container a,
.nb-theme-cosmic .button {
  background: #a16eff;
}

.nb-theme-default .page-item.active .page-link,
.nb-theme-corporate .page-item.active .page-link {
  background-color: #333;
}

.nb-theme-dark .button.unfill,
.nb-theme-cosmic .button.unfill {
  background-color: #ff5c5c !important;
}

/* detail page */

.listview .badge {
  min-width: 52px;
}

.list .badge {
  display: inline-block;
  padding: 6px 12px;
  margin: 2px 4px 2px 0;
  border-radius: 2px;
}

.listview dl {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 14px;
}

.listview .list dt:first-child {
  width: 22%;
  padding-left: 22px;
  font-weight: 600;
  text-overflow: ellipsis;
}

.listview .list dt {
  display: inline-block;
  width: 75%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  vertical-align: top;
  word-wrap: break-word;
}

.color0 {
  background-color: #4c5a7e;
  color: #fff;
}

.color1 {
  background-color: #18c5a9;
  color: #fff;
}

.color2 {
  background-color: #f75a5f;
  color: #fff;
}

.color3 {
  background-color: #fbb84f;
  color: #fff;
}

.color4 {
  background-color: #001dcb;
  color: #fff;
}

.color5 {
  background-color: #f54bb4;
  color: #fff;
}

.nb-theme-dark .form-control {
  color: #fff !important;
}

/* search */

.searchBox {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.searchBox .form-control {
  height: 55px;
  border: 0;
  outline: none;
}

.searchBox .form-control:focus {
  box-shadow: none;
}

.searchBox .search-close {
  position: absolute;
  right: 0;
  width: 55px;
  height: 55px;
  color: #8f9bb3;
  cursor: pointer;
  line-height: 55px;
  text-align: center;
}

.showPassword {
  position: absolute;
  top: 26px;
  right: 10px;
  cursor: pointer;
}

/* input type file css */

.custom-file-uploader {
  position: relative;
  width: 60px;
  height: 103px;
  background: #ccc;
  background: url("assets/images/download.png") no-repeat;
  background-position: center;
  background-size: auto;
  cursor: pointer !important;
}

.custom-file-uploader input[type="file"] {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

/* end */

.hide-action td.ng2-smart-actions a:last-child {
  display: none;
}

.hide-action2 td.ng2-smart-actions a:nth-child(2) {
  display: none;
}

.hideAction td.ng2-smart-actions a:last-child {
  display: none;
}

.hideActions td.ng2-smart-actions a:nth-child(3) {
  display: none;
}

.hideAction1 td.ng2-smart-actions a:nth-child(3) {
  display: none;
}

.hide-all td.ng2-smart-actions a:nth-child(4),
.hide-all td.ng2-smart-actions a:nth-child(3) {
  display: none;
}

.appVersions td:nth-child(5),
.appVersions th:nth-child(5),
.appVersions td:nth-child(6),
.appVersions th:nth-child(6),
.appVersions td:nth-child(7) {
  display: none !important;
}

.achievements td:nth-child(3),
.achievements td:nth-child(4),
.achievements td:nth-child(5),
.achievements td:nth-child(6) {
  display: none !important;
}

.achievementsLevel td:nth-child(3),
.achievementsLevel td:nth-child(4) {
  display: none !important;
}

.category td:nth-child(3),
.category th:nth-child(3),
.category td:nth-child(4),
.category th:nth-child(4),
.category td:nth-child(7) {
  display: none !important;
}

.categorryCss thead tr th:nth-child(4),
.categorryCss thead tr th:nth-child(3),
.categorryCss thead tr th:nth-child(7) {
  display: none !important;
}

.admin tbody tr td:nth-child(6) {
  overflow: auto;
  max-width: 180px !important;
  white-space: nowrap;
}

.admin tbody tr td:nth-child(6) .badge {
  padding: 8px;
  margin-right: 4px;
  border-radius: unset;
}

::-webkit-scrollbar {
  display: none;
}

.App-versionCss thead tr th:nth-child(5),
.App-versionCss thead tr th:nth-child(6),
.App-versionCss thead tr th:nth-child(7) {
  display: none !important;
}

.achievementsCss thead tr th:nth-child(5),
.achievementsCss thead tr th:nth-child(3),
.achievementsCss thead tr th:nth-child(4),
.achievementsCss thead tr th:nth-child(6) {
  display: none !important;
}

.achievementsLevel thead tr th:nth-child(3),
.achievementsLevel thead tr th:nth-child(4) {
  display: none !important;
}

.crose1 {
  position: absolute;
  top: 32px;
  left: 82px;
  padding: 6px;
  background: red;
  border-radius: 20px;
  color: #fff !important;
}

/* alerts */

.success-alert .swal2-popup.swal2-toast .swal2-title {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  line-height: 30px;
}

.success-alert .swal2-popup.swal2-toast .swal2-html-container {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  font-size: 14px;
}

.error-alert .swal2-popup.swal2-toast .swal2-title {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  color: #ff9aa1;
  line-height: 30px;
}

.error-alert .swal2-popup.swal2-toast .swal2-html-container {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  color: #ff9aa1;
  font-size: 14px;
}

.confirm-alert h2 {
  color: #333;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0;
}

.confirm-alert .swal2-html-container {
  display: block;
  margin-top: 10px;
  color: #282828;
  font-size: 15px;
}

.confirm-alert .swal2-actions {
  margin-top: 10px;
}

.confirm-alert .swal2-styled.swal2-confirm {
  width: 92px;
  background-color: #282828 !important;
  border-radius: 2px;
}

.confirm-alert .swal2-styled.swal2-cancel {
  width: 92px;
  background-color: #ff5c5c;
  border-radius: 2px;
  outline: none;
}

.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}

.modal-header h2 {
  margin-bottom: 5px;
  color: #333;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 29px;
}

/* dashboard */

.card {
  overflow: hidden;
  min-height: 116px;
  border: 0;
  box-shadow: 0 6px 0 0 rgb(0 0 0 / 1%), 0 15px 32px 0 rgb(0 0 0 / 6%);
}

.page-wrapper {
  position: relative;
  width: 100%;
  min-height: 100%;
}

.card-body .easypie {
  position: relative;
  margin-top: 8px;
  text-align: center;
}

.card-body .easypie i {
  display: inline-block;
  width: 58px;
  height: 58px;
  background: #e4e7ec;
  border-radius: 50%;
  color: #404e67;
  font-size: 30px;
  line-height: 58px;
  text-align: center;
}

.card-body {
  width: 100%;
}

.card-body.text-success .easypie i {
  background-color: #d7f9f6;
  color: #84d9d2;
  font-size: 26px;
}

.card-body.text-primary .easypie i {
  background: #ffe2e4;
  color: #f75a5f;
  font-size: 32px;
}

.easypie-data.pd-set i {
  font-size: 23px;
}

.card-body.text-pink .easypie i {
  background-color: rgb(255 242 210 / 90%) !important;
  color: rgb(255 205 86 / 90%);
}

.card-body.text-pink {
  width: 100%;
}

.card-body.text-red .easypie i {
  background-color: #ffe4f0 !important;
  color: #d26e9c;
}

.card-body.yellow .easypie i {
  background: #ffecce;
  color: #fccd83;
}

.report-sroller {
  min-height: 205px;
}

.card-body h3 {
  margin-bottom: 0;
  color: #222b45;
  font-size: 24px;
  font-weight: 600;
}

.ibox {
  position: relative;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 6px 0 0 rgb(0 0 0 / 1%), 0 15px 32px 0 rgb(0 0 0 / 6%);
}

.ibox .ibox-head {
  display: box;
  display: flex;
  display: flexbox;
  display: flex;
  min-height: 60px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  padding: 15px;
  border-bottom: 1px solid #eee;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
}

.ibox .ibox-head .ibox-title {
  margin-right: 74px;
  color: #222b45 !important;
  font-size: 14px;
  font-weight: 600;
}

.ibox .ibox-head .ibox-title.services_name {
  margin-right: 0;
}

.col-lg-8.pd-8 {
  padding-right: 7px;
}

.ibox .ibox-body {
  padding: 20px;
}

.flexbox-b {
  display: box !important;
  display: flex !important;
  display: flexbox !important;
  display: flex !important;
  align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.ibox-tools ul {
  padding: 0;
  margin: 0;
}

.ibox-tools ul li {
  list-style: none;
}

.list-group .list-group-item {
  padding: 15px 0;
  border-color: transparent;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
  background-color: transparent;
}

.float-right {
  float: right !important;
}

.list-group .list-group-item:last-child {
  border: 0;
}

.chartTable nb-tabset .tab-link {
  line-height: 1.75rem !important;
}

.chartTable table tr td,
.chartTable table tr th {
  padding: 1em !important;
}

.chartTable nb-tabset .tab.active .tab-link {
  color: #222b45;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1rem;
  text-transform: capitalize;
}

.chartTable nb-tabset .tabset .tab a::before {
  bottom: -10px;
  border-radius: 1px;
}

.chartTable nb-tabset .tab-link::before {
  height: 0.15rem;
}

.chartTable nb-tabset .tab.active .tab-link::before {
  background-color: #222b45;
}

.hideAction td.ng2-smart-actions a {
  display: none !important;
}

.reportSection table tr td {
  padding: 1em !important;
}

nb-card {
  border: 0;
}

/* theme color change */

.nb-theme-dark .ibox,
.nb-theme-dark .card {
  background-color: #222b45;
}

.nb-theme-dark .ibox .ibox-head .ibox-title {
  color: #fff !important;
}

.nb-theme-dark .card-body h3,
.nb-theme-dark .text-muted {
  color: #fff !important;
}

.nb-theme-dark .list-group-item {
  color: #fff;
}

.nb-theme-dark .list-group .list-group-item {
  border-bottom: 1px solid #151a30;
}

.nb-theme-dark .ibox .ibox-head {
  border-bottom: 1px solid #151a30;
}

.nb-theme-dark .card-body.text-success .easypie i,
.nb-theme-dark .card-body .easypie i,
.nb-theme-dark .card-body.text-primary .easypie i {
  background-color: #161a30;
  color: #fff;
}

.setThemes {
  margin-top: 30px !important;
}

/** nb-theme-cosmic **/

.nb-theme-cosmic .login-container.ibox,
.nb-theme-cosmic .ibox,
.nb-theme-corporate .login-container.ibox,
.nb-theme-corporate .login-container .ibox,
.nb-theme-default .login-container .ibox {
  box-shadow: none !important;
}

.nb-theme-cosmic .ibox,
.nb-theme-cosmic .card {
  background-color: #323259;
}

.nb-theme-cosmic .ibox .ibox-head .ibox-title {
  color: #fff !important;
}

.nb-theme-cosmic .card-body h3,
.nb-theme-dark .text-muted {
  color: #fff !important;
}

.nb-theme-cosmic .list-group-item {
  color: #fff;
}

.nb-theme-cosmic .list-group .list-group-item {
  border-bottom: 1px solid #151a30;
}

.nb-theme-cosmic .ibox .ibox-head {
  border-bottom: 1px solid #151a30;
}

.nb-theme-cosmic .card-body.text-success .easypie i,
.nb-theme-cosmic .card-body .easypie i,
.nb-theme-cosmic .card-body.text-primary .easypie i {
  background-color: #161a30;
  color: #fff;
}

/* cosmic */

.nb-theme-cosmic .searchBox .form-control {
  border-color: #13132b;
  background-color: #252547;
  color: #fff;
}

.nb-theme-dark .swal2-container.swal2-center > .swal2-popup {
  border: 0.0625rem solid #101426;
  background-color: #222b45;
}

.nb-theme-cosmic .swal2-container.swal2-center > .swal2-popup {
  border: 0.0625rem solid #13132b;
  background-color: #323259;
}

.nb-theme-dark .confirm-alert h2,
.nb-theme-cosmic .confirm-alert h2 {
  color: #fff;
}

.nb-theme-dark .checkbox .input-helper::after,
.nb-theme-cosmic .checkbox .input-helper::after {
  border-bottom: 2px solid #ffff;
  border-left: 2px solid #fff;
}

.nb-theme-cosmic .inner-page.login-wrapper .col-set-6 {
  background: none;
}

.nb-theme-cosmic .inner-page .col-set-6 {
  border: 0.0625rem solid #13132b;
  background-color: #252547;
  color: #fff;
}

.nb-theme-cosmic .inner-page.loginType_3 .form-element.upload {
  background: none !important;
}

.nb-theme-cosmic .inner-page.loginType_3 .form-element {
  border-color: #13132b;
  background-color: #252547;
}

.nb-theme-cosmic .loginType_3.inner-page .form-element input[type="text"],
.nb-theme-cosmic .loginType_3.inner-page .form-element input[type="password"],
.nb-theme-cosmic .loginType_3.inner-page .form-element input[type="email"],
.nb-theme-cosmic .loginType_3.inner-page .form-element input [type="search"],
.nb-theme-cosmic .loginType_3.inner-page .form-element select,
.nb-theme-cosmic .form-element textarea {
  border-bottom: 0;
  background-color: #252547 !important;
  color: #fff;
}

.nb-theme-cosmic nb-tabset .tab.active .tab-link::before {
  background-color: #a16eff;
}

.nb-theme-cosmic .inner-page.commonSpacing .col-set-6 h2 {
  border-bottom: 1px solid #1b1b38;
}

.nb-theme-dark .add-more-container a,
.nb-theme-dark .button,
.nb-theme-dark .confirm-alert .swal2-styled.swal2-confirm,
.nb-theme-dark .swal2-styled.swal2-confirm {
  background-color: #36f !important;
}

.nb-theme-cosmic .add-more-container a,
.nb-theme-cosmic .button,
.nb-theme-cosmic .confirm-alert .swal2-styled.swal2-confirm,
.nb-theme-cosmic .swal2-styled.swal2-confirm {
  background-color: #a16eff !important;
}

.nb-theme-cosmic .background_323259 {
  background-color: #323259 !important;
}

.center {
  position: relative;
  top: -13px;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
}

/** chat **/

.chat-history ul {
  padding-left: 0;
}

.circleIcon nb-icon {
  font-size: 30px;
}

.sendButton nb-icon {
  color: #fff;
  font-size: 24px;
}

.nb-theme-dark .chat-message-content,
.nb-theme-dark .align-right .chat-message-content {
  background: #222b45 !important;
}

.nb-theme-dark .chat-content .align-right .chat-message-content p {
  color: #fff !important;
}

.nb-theme-dark .chat-content .bottom-box {
  background: #222b45 !important;
}

.nb-theme-dark .chat-content .bottom-box .inputType {
  border: 1px solid #151a30 !important;
  background: #151a30 !important;
  color: #fff !important;
}

.nb-theme-dark .chat-content .bottom-box .sendButton {
  background: #36f !important;
}

/** chat theme 3 **/

.nb-theme-cosmic .chat-message-content,
.nb-theme-cosmic .align-right .chat-message-content {
  background: #222b45 !important;
}

.nb-theme-cosmic .chat-content .align-right .chat-message-content p {
  color: #fff !important;
}

.nb-theme-cosmic .chat-content .bottom-box {
  background: #323259 !important;
}

.nb-theme-cosmic .chat-content .bottom-box .inputType {
  border: 1px solid #151a30 !important;
  background: #151a30 !important;
  color: #fff !important;
}

.nb-theme-cosmic .chat-content .bottom-box .sendButton {
  background: #a16eff !important;
}

/** comment **/

.nb-theme-dark .comment-box,
.nb-theme-dark .comment-list {
  background: #222b45 !important;
}

.nb-theme-dark .comment-box p,
.nb-theme-cosmic .comment-list {
  color: #fff !important;
}

.nb-theme-dark .comment-box .inputType {
  border: 1px solid #151a30 !important;
  background: #151a30 !important;
  color: #fff !important;
}

.nb-theme-dark .comment-box .post-button {
  background: #36f !important;
}

/** comment theme 3 **/

.nb-theme-cosmic .comment-box,
.nb-theme-cosmic .comment-list {
  background: #323259 !important;
}

.nb-theme-cosmic .comment-box p,
.nb-theme-cosmic .comment-list {
  color: #fff !important;
}

.nb-theme-cosmic .comment-box .inputType {
  border: 1px solid #151a30 !important;
  background: #151a30 !important;
  color: #fff !important;
}

.nb-theme-cosmic .comment-box .post-button {
  background: #a16eff !important;
}
